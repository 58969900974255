import { useRouter } from "next13-progressbar";

export const useGoBack = ({
    fallbackHref = "/",
    backToHome = false,
}: {
    fallbackHref?: string;
    backToHome?: boolean;
} = {}) => {
    const router = useRouter();

    return () => {
        if (backToHome) {
            router.replace("/");
        } else {
            if (window.history.length > 1) {
                router.back();
            } else {
                router.replace(fallbackHref);
            }
        }
    };
};
