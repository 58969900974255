import { ACCENT, FLASHCARD_LANGUAGES, FLASHCARD_LANGUAGES_ENUM } from "@knowt/syncing/hooks/flashcards/languageUtils";
import React, { useState } from "react";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import CircularButton from "@/components/CircularButton";
import { themeColors } from "@/utils/themeColors";
import { ArrowBigUp } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";

export type AccentButtonsProps = {
    language?: FLASHCARD_LANGUAGES_ENUM;
    onClick: (char: string, event: React.MouseEvent<HTMLElement>) => void;
};

const AccentButtons = ({ language = "ENGLISH", onClick }: AccentButtonsProps) => {
    const [isForceCaps, setIsForceCaps] = useState(false);
    const [isCapsFromKeyboardPressed, setIsCapsFromKeyboardPressed] = useState(false);

    const isCapsLockOn = isForceCaps || isCapsFromKeyboardPressed;

    const accentChars = ACCENT[isCapsLockOn ? "UPPER" : "LOWER"][FLASHCARD_LANGUAGES[language]];

    useCapsLockHandler(isCapsLockActive => {
        setIsCapsFromKeyboardPressed(isCapsLockActive);
    });

    if (language === FLASHCARD_LANGUAGES.ENGLISH || !language || !accentChars) {
        return null;
    }

    return (
        <>
            {accentChars.map((char, i) => {
                return (
                    <CircularButton
                        radius="3.5rem"
                        key={char + i}
                        sx={{
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.08)",
                            fontSize: "1.6rem",
                            backgroundColor: "var(--color-neutral-2)",
                        }}
                        onClick={e => onClick(char, e)}>
                        {char}
                    </CircularButton>
                );
            })}
            {accentChars.length > 0 && (
                <CircularButton
                    radius="3.5rem"
                    aria-label="shift caps"
                    sx={{
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.08)",
                        fontSize: "1.6rem",
                        backgroundColor: isCapsLockOn ? themeColors.inverted : "var(--color-neutral-2)",
                    }}
                    onClick={() => setIsForceCaps(!isForceCaps)}>
                    <ArrowBigUp
                        strokeWidth={strokeWidth.normal}
                        size={iconSizes.SM + 6}
                        color={themeColors.neutral2}
                        fill={themeColors.neutral2}
                    />
                </CircularButton>
            )}
        </>
    );
};

export default AccentButtons;

const useCapsLockHandler = (isCapsLockActiveCb: (val: boolean) => void) => {
    const handler = event => {
        // `getModifierState` is a method of the DOM's KeyboardEvent interface but this method is not available in all platforms.
        // One example that is not available is on 'ipad'
        if (typeof event?.getModifierState !== "function") {
            return;
        }

        if (event.getModifierState("CapsLock")) {
            if (event.shiftKey) {
                isCapsLockActiveCb(false);
            } else {
                isCapsLockActiveCb(true);
            }
        } else {
            if (event.shiftKey) {
                isCapsLockActiveCb(true);
            } else {
                isCapsLockActiveCb(false);
            }
        }
    };

    useDOMEventListener("keydown", handler);
    useDOMEventListener("keyup", handler);
};
