"use client";

import React from "react";
import { ArrowLeft } from "lucide-react";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import CircularRectTextButton from "./CircularRectTextButton";
import Circular from "@/components/Circular";
import { themeColors } from "@/utils/themeColors";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { useGoBack } from "@/utils/navigation/utils";

type GoBackButtonProps = {
    sx?: SxProps;
    fallbackHref?: string;
    backToHome?: boolean;
    iconStyle?: React.CSSProperties;
    className?: string;
    onClick?: () => void;
};

const GoBackButton = ({ sx, iconStyle, fallbackHref, backToHome, className, onClick }: GoBackButtonProps) => {
    const goBack = useGoBack({ fallbackHref, backToHome });

    return (
        <CircularRectTextButton
            onClick={() => {
                if (onClick) onClick();
                else goBack();
            }}
            className={className}
            sx={{
                "&:hover": {
                    backgroundColor: themeColors.neutralOverlay,
                },
                "&:active": {
                    transform: "scale(0.97)",
                },
                width: "12.5rem",
                justifyContent: "flex-start",
                paddingInline: "5px",
                height: "4.5rem",
                fontSize: "1.5rem",
                gap: "0.8rem",
                transition: "all 0.2s ease-in-out",
                ...sx,
            }}>
            <Circular radius="3rem" style={{ backgroundColor: themeColors.primary, ...iconStyle }}>
                <ArrowLeft strokeWidth={strokeWidth.normal} size={iconSizes.SM} color={themeColors.neutralWhite} />
            </Circular>
            go back
        </CircularRectTextButton>
    );
};

export default GoBackButton;
