import { EventFunction } from "@/types/common";
import { X } from "lucide-react";
import Image from "next/image";
import React, { useState } from "react";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import CircularButton from "@/components/CircularButton";
import { preventStopPropogation } from "@/utils/domUtils";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import ZoomableImage from "./ZoomableImage";
import { themeColors } from "@/utils/themeColors";

type AddRemoveImageButtonProps = {
    image?: string | null;
    editable: boolean;
    alt?: string;
    style?: React.CSSProperties;
    // the box's "width x height" or approx box's height if the container pass custom width/height to `style`
    // this is helpful to ease of calculations for the badge/image dimensions
    size?: string;
    onDelete?: EventFunction;
    className?: string;
};

const AddRemoveImageButton = ({
    image,
    alt,
    style,
    size = "7.3rem",
    editable,
    onDelete,
    className,
}: AddRemoveImageButtonProps) => {
    const [isImgInView, setIsImgInView] = useState(false);

    const magnifyImg = (e: React.MouseEvent) => {
        preventStopPropogation(e);
        setIsImgInView(true);
    };

    const renderPreviewImage = () => {
        return (
            <Image
                src={image}
                alt={alt || "knowt flashcard image"}
                style={{ objectFit: "scale-down", cursor: "zoom-in", width: "100%", height: "auto" }}
                width={150}
                height={150}
                onClick={magnifyImg}
            />
        );
    };

    const renderBadge = () => {
        if (!image || !editable) {
            return null;
        }

        return (
            <CircularButton
                tabIndex={-1}
                onClick={onDelete}
                radius={`calc(${size}/3.6)`}
                sx={{
                    backgroundColor: "rgba(241, 136, 136, 1)",
                    position: "absolute",
                    top: "-1rem",
                    left: "-1rem",
                }}>
                <X size={iconSizes.MD} strokeWidth={strokeWidth.normal} color={themeColors.pureWhite} />
            </CircularButton>
        );
    };

    return (
        <React.Fragment>
            <FlexColumnAlignJustifyCenter style={{ position: "relative" }}>
                {/* THE IMAGE */}
                <FlexColumnAlignJustifyCenter
                    className={className}
                    style={{
                        width: size,
                        height: size,
                        borderRadius: "0.25rem",
                        overflow: "hidden",
                        position: "relative",
                        ...style,
                    }}>
                    {image && renderPreviewImage()}
                </FlexColumnAlignJustifyCenter>
                {/* DELETE ICON ON TOP LEFT */}
                {renderBadge()}
            </FlexColumnAlignJustifyCenter>

            {/* THE ZOOMED IMAGE */}
            <ZoomableImage
                isOpen={isImgInView}
                onClose={() => setIsImgInView(false)}
                img={image as string}
                alt={alt || "knowt flashcard image"}
            />
        </React.Fragment>
    );
};

export default AddRemoveImageButton;
