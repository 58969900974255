import { Editor, EditorContent, useEditor } from "@knowt/editor/react";
import { AICompletionType, Language } from "@knowt/syncing/graphql/schema";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import noop from "@knowt/syncing/utils/noop";
import { uploadPictureToS3 } from "@knowt/syncing/utils/s3";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { AccentSuggestionsWrapper, ClickableLanguagePopup } from "./SharedComponents";
import styles from "./flashcardEditor.module.css";
import AiInputMenu from "./AiInputMenu";
import { Flex, FlexColumn } from "@/components/Flex";
import {
    AIPrompt,
    FLASHCARDS_EDITOR_CLASSNAME,
} from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";

import { EditorFlashcard, GetAIResponseFromPromptType } from "@/components/FullPageEditor/AiInputMenu/types";
import { AiInputMenuProps } from "@/components/FullPageEditor/EditorContext";
import SlashMenu from "@/components/FullPageEditor/SlashMenu/SlashMenu";
import { MenuProps as SlashMenuProps } from "@/components/FullPageEditor/SlashMenu/types";
import { extensions } from "@/components/FullPageEditor/extensions";
import { Menus, MENUS_STATE_META_KEY } from "@/components/FullPageEditor/extensions/MenusState";
import { useAiPrivacyContextSelector } from "@/components/Popup/AiPopup/AiPrivacyPopupContext";
import { preventClickedAreaToLostInputFocus } from "@/utils/domUtils";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { useContainerBreakPoints } from "@/hooks/styles/useBreakpoints";
import { getPasteTransformers } from "@/components/FullPageEditor/utils";

import "@/components/FullPageEditor/richEditor.css";
import "katex/dist/katex.min.css";
import SelectionMenu from "@/components/FullPageEditor/SelectionMenu";

const DEFAULT_ACCENT_PROPS = {
    language: "",
    onLanguageSelected: noop,
    isAccentButtonsVisible: false,
    isClickableLanguageVisible: false,
};

export type FlashcardEditorHandle = {
    openAiInputMenu: () => void;
    forceUpdateContent: (content: string) => void;
};

type FlashcardEditorProps = {
    handleInputChange: (content: string) => void;
    defaultValue: string;
    handleImagePaste: (image: string) => void;
    dataTestId?: string;
    editorProps: {
        flashcard: EditorFlashcard;
        onFocus?: ({ editor }: { editor: Editor }) => void;
        onBlur?: () => void;
    };
    label?: string;
    accentProps: {
        language?: Language;
        onLanguageSelected?: (language: Language) => void;
        isAccentButtonsVisible?: boolean;
        isClickableLanguageVisible?: boolean;
    };
    aiProps?: {
        getAIResponseFromPrompt: GetAIResponseFromPromptType;
        context: Record<string, string>;
    };
};

const FlashcardEditor = forwardRef<FlashcardEditorHandle, FlashcardEditorProps>(
    (
        {
            handleInputChange,
            defaultValue,
            accentProps: _accentProps,
            editorProps,
            handleImagePaste,
            label,
            dataTestId,
            aiProps,
        },
        ref
    ) => {
        const setIsAiPrivacyPopupOpen = useAiPrivacyContextSelector(context => context.setIsAiPrivacyPopupOpen);
        const shouldDisplayAiPrivacyPopup = useAiPrivacyContextSelector(context => context.shouldDisplayAiPrivacyPopup);
        const setOpenAiInputMenu = useAiPrivacyContextSelector(context => context.setOpenAiInputMenu);

        const [slashMenuProps, updateSlashMenuProps] = useCombinedState<SlashMenuProps>({ isOpen: false });

        const slashMenuRef = useRef({ handleKeyDown: (_e: KeyboardEvent) => false });

        const editorInputRef = useRef<HTMLDivElement>(null);

        const { mdDown, smDown } = useContainerBreakPoints({
            container: editorInputRef.current ?? document.body,
            sm: 220,
            md: 300,
        });

        const [aiInputMenuProps, updateAiInputMenuProps] = useCombinedState<AiInputMenuProps>({
            isOpen: false,
            selectedPrompt: undefined,
            optimisticText: undefined,
        });

        const handleChooseAiPrompt = async (prompt: AIPrompt) => {
            switch (prompt.type) {
                case AICompletionType.GENERIC_FLASHCARD:
                    return handleOpenAiInputMenu();
                default:
                    return handleOpenAiInputMenu({ selectedPrompt: prompt });
            }
        };

        const handleChooseAiPromptRef = useRef(handleChooseAiPrompt);
        useEffect(() => {
            handleChooseAiPromptRef.current = handleChooseAiPrompt;
        });

        const handleOpenAiInputMenu = ({
            selectedPrompt,
            optimisticText,
        }: {
            selectedPrompt?: AIPrompt;
            optimisticText?: string;
        } = {}) => {
            if (aiInputMenuProps.isOpen) return;

            if (shouldDisplayAiPrivacyPopup()) {
                setIsAiPrivacyPopupOpen(true);
                setOpenAiInputMenu(() => () => openAiInputMenu({ selectedPrompt, optimisticText }));
                return;
            }

            openAiInputMenu({ selectedPrompt, optimisticText });
        };

        const openAiInputMenu = ({ selectedPrompt, optimisticText }) => {
            editor?.commands.setMeta(MENUS_STATE_META_KEY, { [Menus.AiInputMenu]: true });
            updateAiInputMenuProps({ isOpen: true, selectedPrompt, optimisticText });
        };

        const handleCloseAiInputMenu = () => {
            if (!aiInputMenuProps.isOpen) return;

            editor?.commands.setMeta(MENUS_STATE_META_KEY, { [Menus.AiInputMenu]: false });
            updateAiInputMenuProps({ isOpen: false });
        };

        const handleOpenAiInputMenuRef = useRef(handleOpenAiInputMenu);
        useEffect(() => {
            handleOpenAiInputMenuRef.current = handleOpenAiInputMenu;
        });

        const handlePaste = (_, event: ClipboardEvent) => {
            if (!event.clipboardData) return;

            // Check if the image was copied from PowerPoint
            const text = event.clipboardData.getData("text/plain");
            if (text) return;

            const pastedImage = Array.from(event.clipboardData.items).find(({ type }) => type.startsWith("image/"));
            if (!pastedImage) return;

            const blob = pastedImage.getAsFile();
            if (blob === null) return;

            const contentType = blob.type;

            uploadPictureToS3(blob, "knowt-user-attachments", contentType).then(handleImagePaste);

            return true;
        };

        const editor = useEditor({
            content: defaultValue,
            editable: true,
            extensions: extensions({
                slashCommandOptions: {
                    updateMenuProps: updateSlashMenuProps,
                    aiCommands: [
                        AICompletionType.GENERIC_FLASHCARD,
                        AICompletionType.SIMPLE_FLASHCARD,
                        AICompletionType.FILL_BLANK_FLASHCARD,
                        AICompletionType.QUESTION_FLASHCARD,
                        AICompletionType.TRANSLATE_FLASHCARD,
                        AICompletionType.LIST,
                        AICompletionType.KEY_INFO,
                        AICompletionType.HIST_EVENT,
                        AICompletionType.EXAMPLE,
                        AICompletionType.FORMULA,
                    ],
                    handleChooseAiPromptRef,
                    componentRef: slashMenuRef,
                },
                aiInputMenuTriggerOptions: { openRef: handleOpenAiInputMenuRef, disabled: !aiProps },
                placeholderOptions: { placeholder: aiProps ? "“space” for AI, “/” for format" : "“/” for format" },
            }),
            onUpdate: ({ editor }) => handleInputChange(editor.getHTML()),
            onFocus: ({ editor }) => editorProps.onFocus?.({ editor }),
            onBlur: ({ editor }) => editorProps.onBlur?.({ editor }),
            editorProps: {
                handlePaste,
                ...getPasteTransformers(),
            },
        });

        useImperativeHandle(ref, () => ({
            openAiInputMenu: handleOpenAiInputMenu,
            forceUpdateContent: content => editor?.commands.setContent(content),
        }));

        const { isDarkMode } = useTheme();

        const accentProps = { ...DEFAULT_ACCENT_PROPS, ..._accentProps };

        return (
            <FlexColumn ref={editorInputRef} className={styles.wrapper} data-testid={dataTestId}>
                <style>
                    {`.ProseMirror {
                     font-size: ${smDown ? "1rem" : mdDown ? "1.4rem" : "1.6rem"};
                         }`}
                </style>
                <EditorContent
                    editor={editor}
                    className={FLASHCARDS_EDITOR_CLASSNAME}
                    style={{
                        flex: 1,
                        position: "relative",
                        borderBottom: `2px solid ${editor?.isFocused ? themeColors.primary : themeColors.inverted}`,
                        background: "transparent",
                    }}
                />
                <SelectionMenu
                    editor={editor}
                    handleOpenAiInputMenu={handleOpenAiInputMenu}
                    showForTextSelection={false}
                />
                {editor && (
                    <SlashMenu
                        ref={slashMenuRef}
                        command={slashMenuProps.command ?? noop}
                        isActive={slashMenuProps.isOpen ?? false}
                        view={editor.view}
                        search={slashMenuProps.query ?? null}
                        groups={slashMenuProps.items ?? []}
                        clientRect={slashMenuProps.clientRect ?? null}
                        isDarkMode={isDarkMode}
                    />
                )}
                {editor && aiProps && (
                    <AiInputMenu
                        isActive={aiInputMenuProps.isOpen}
                        onClose={handleCloseAiInputMenu}
                        selectedPrompt={aiInputMenuProps.selectedPrompt}
                        optimisticText={aiInputMenuProps.optimisticText}
                        inputMenu={[
                            AICompletionType.SIMPLE_FLASHCARD,
                            AICompletionType.FILL_BLANK_FLASHCARD,
                            AICompletionType.QUESTION_FLASHCARD,
                            AICompletionType.TRANSLATE_FLASHCARD,
                            AICompletionType.LIST,
                            AICompletionType.KEY_INFO,
                            AICompletionType.EXAMPLE,
                            AICompletionType.HIST_EVENT,
                            AICompletionType.FORMULA,
                        ]}
                        highlightMenu={[
                            AICompletionType.SIMPLE_FLASHCARD,
                            AICompletionType.FILL_BLANK_FLASHCARD,
                            AICompletionType.TRANSLATE_FLASHCARD,
                            AICompletionType.LIST,
                            AICompletionType.KEY_INFO,
                            AICompletionType.EXAMPLE,
                            AICompletionType.HIST_EVENT,
                            AICompletionType.FORMULA,
                            AICompletionType.MAKE_LONGER,
                            AICompletionType.MAKE_SHORTER,
                        ]}
                        editor={editor}
                        flashcard={editorProps.flashcard}
                        getAIResponseFromPrompt={aiProps.getAIResponseFromPrompt}
                        context={aiProps.context}
                    />
                )}
                <Flex
                    style={{
                        padding: "1rem 0 0.5rem 0",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    {label && <p className="bodyBold1">{label}</p>}
                    <FlexColumn
                        onMouseDown={event => preventClickedAreaToLostInputFocus(event, event.target)}
                        style={{ width: "100%" }}>
                        <div style={{ marginLeft: "auto" }}>
                            <ClickableLanguagePopup
                                side={editorProps.flashcard.side}
                                language={accentProps.language}
                                isClickableTextVisible={accentProps.isClickableLanguageVisible}
                                onLanguageSelected={lang => accentProps.onLanguageSelected(lang)}
                            />
                        </div>
                        <div style={{ padding: "0.5rem 0" }}>
                            <AccentSuggestionsWrapper
                                language={accentProps.language}
                                isAccentButtonsVisible={accentProps.isAccentButtonsVisible}
                                onAccentSelected={char => editor?.commands.insertContent(char)}
                            />
                        </div>
                    </FlexColumn>
                </Flex>
            </FlexColumn>
        );
    }
);

FlashcardEditor.displayName = "FlashcardEditor";

export default FlashcardEditor;
