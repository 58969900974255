import { Editor } from "@knowt/editor/react";
import noop from "@knowt/syncing/utils/noop";
import {
    Baseline,
    BoldIcon,
    FileCodeIcon,
    Highlighter,
    ItalicIcon,
    ListIcon,
    ListOrderedIcon,
    RedoIcon,
    RemoveFormattingIcon,
    StrikethroughIcon,
    UnderlineIcon,
    UndoIcon,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useUpdate } from "react-use";
import { FlexRowAlignCenter } from "@/components/Flex";
import ColorMenu from "@/components/FullPageEditor/SelectionMenu/ColorMenu";
import HighlightMenu from "@/components/FullPageEditor/SelectionMenu/HighlightMenu";
import IconButton from "@/components/FullPageEditor/Toolbar/IconButton";
import Separator from "@/components/FullPageEditor/Toolbar/Separator";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { borderRadius } from "@/utils/borderRadius";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";

type FlashcardEditorToolbarProps = {
    editor: Editor | null;
    style?: React.CSSProperties;
};

const FlashcardEditorToolbar = ({ editor, style }: FlashcardEditorToolbarProps) => {
    const isDisabled = editor === null;

    const { smDown } = useBreakPoints({ sm: 570 });

    const forceRender = useUpdate();

    useEffect(() => {
        if (editor) {
            const cb = () => forceRender();

            editor?.on("transaction", cb);
            return () => editor?.off("transaction", cb);
        }

        return noop; // for ts
    }, [editor, forceRender]);

    const editorRef = useRef<Editor | null>(null);

    useEffect(() => {
        if (!editor && (isColorMenuOpen || isHighlightMenuOpen)) {
            // when the menu opens, it unfocuses on the editor, but we still need to keep the reference
            return;
        }
        editorRef.current = editor;
    }, [editor]);

    const [isColorMenuOpen, setIsColorMenuOpen] = useState(false);
    const [isHighlightMenuOpen, setIsHighlightMenuOpen] = useState(false);

    const colorMenuRef = useRef<HTMLButtonElement>(null);
    const highlightMenuRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <FlexRowAlignCenter
                onMouseDown={event => {
                    // to not blur the input while using the toolbar
                    event.preventDefault();
                }}
                style={{
                    opacity: isDisabled ? 0.5 : 1,
                    borderRadius: borderRadius.shortInput,
                    backgroundColor: themeColors.background,
                    boxShadow: `0px 4px 4px var(--color-neutral-overlay)`,
                    height: "3.8rem",
                    paddingInline: spacing.SM,
                    ...style,
                }}>
                <IconButton
                    tooltip="Bold"
                    disabled={isDisabled || !editor?.can().toggleBold()}
                    onClick={() => editor?.chain().focus().toggleBold().run()}
                    Icon={BoldIcon}
                    isActive={editor?.isActive("bold")}
                />
                <IconButton
                    tooltip="Italic"
                    disabled={isDisabled || !editor?.can().toggleItalic()}
                    onClick={() => editor?.chain().focus().toggleItalic().run()}
                    Icon={ItalicIcon}
                    isActive={editor?.isActive("italic")}
                />
                <IconButton
                    tooltip="Underline"
                    disabled={isDisabled || !editor?.can().toggleUnderline()}
                    onClick={() => editor?.chain().focus().toggleUnderline().run()}
                    Icon={UnderlineIcon}
                    isActive={editor?.isActive("underline")}
                />
                <IconButton
                    tooltip="Strikethrough"
                    disabled={isDisabled || !editor?.can().toggleStrike()}
                    onClick={() => editor?.chain().focus().toggleStrike().run()}
                    Icon={StrikethroughIcon}
                    isActive={editor?.isActive("strike")}
                />
                {!smDown && (
                    <IconButton
                        ref={colorMenuRef}
                        tooltip="Color"
                        disabled={isDisabled || !editor?.can().setMark("textStyle")}
                        onClick={() => setIsColorMenuOpen(true)}
                        Icon={Baseline}
                        isActive={!!editor?.getAttributes("textStyle").color}
                    />
                )}
                {!smDown && (
                    <IconButton
                        ref={highlightMenuRef}
                        tooltip="Highlight"
                        disabled={isDisabled || !editor?.can().toggleHighlight()}
                        onClick={() => setIsHighlightMenuOpen(true)}
                        Icon={Highlighter}
                        isActive={editor?.isActive("highlight")}
                    />
                )}
                <Separator />
                {!smDown && (
                    <IconButton
                        tooltip="Code block"
                        disabled={isDisabled || !editor?.can().toggleCodeBlock()}
                        onClick={() => editor?.chain().focus().toggleCodeBlock().run()}
                        Icon={FileCodeIcon}
                        isActive={editor?.isActive("codeBlock")}
                    />
                )}
                <IconButton
                    tooltip="Bullet list"
                    disabled={isDisabled || !editor?.can().toggleBulletList()}
                    onClick={() => editor?.chain().focus().toggleBulletList().run()}
                    Icon={ListIcon}
                    isActive={editor?.isActive("bulletList")}
                />
                <IconButton
                    tooltip="Ordered list"
                    disabled={isDisabled || !editor?.can().toggleOrderedList()}
                    onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                    Icon={ListOrderedIcon}
                    isActive={editor?.isActive("orderedList")}
                />
                {!smDown && (
                    <IconButton
                        tooltip="Remove formatting"
                        disabled={isDisabled}
                        onClick={() => editor?.chain().focus().unsetAllMarks().run()}
                        Icon={RemoveFormattingIcon}
                    />
                )}
                <Separator />
                <IconButton
                    tooltip="Undo"
                    disabled={isDisabled || !editor?.can().undo()}
                    onClick={() => editor?.commands.undo()}
                    Icon={UndoIcon}
                />
                <IconButton
                    tooltip="Redo"
                    disabled={isDisabled || !editor?.can().redo()}
                    onClick={() => editor?.commands.redo()}
                    Icon={RedoIcon}
                />
            </FlexRowAlignCenter>
            <ColorMenu
                editor={editorRef.current}
                isOpen={isColorMenuOpen}
                anchorEl={colorMenuRef.current}
                onClose={() => setIsColorMenuOpen(false)}
            />
            <HighlightMenu
                editor={editorRef.current}
                isOpen={isHighlightMenuOpen}
                anchorEl={highlightMenuRef.current}
                onClose={() => setIsHighlightMenuOpen(false)}
            />
        </>
    );
};

export default FlashcardEditorToolbar;
