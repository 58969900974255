import { uploadPictureToS3 } from "@knowt/syncing/utils/s3";
import { Image as ImageIcon } from "lucide-react";
import React, { useState } from "react";
import AddRemoveImageButton from "@/components/AddRemoveImageButton";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { getImgDimensions } from "@/utils/fileUtils";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

type ImageButtonProps = {
    image?: string | null;
    onImageChange: (params: { image: string | null; width: number; height: number }) => Promise<void | string>;
    tabIndex: number;
};
export const ImageButton = ({ image: defaultImage, onImageChange, tabIndex }: ImageButtonProps) => {
    const [inputImg, setInputImg] = useState(null);

    const handleImagePickerInputChange = async event => {
        const file = event.target.files[0];

        event.target.value = "";
        if (!file) return;

        const imgUrl = await uploadPictureToS3(file, "knowt-user-attachments", file.type);

        const { width, height } = await getImgDimensions(imgUrl);

        await onImageChange({ image: imgUrl, width, height });

        setInputImg(null);
    };

    if (!defaultImage && !inputImg) {
        return (
            <div
                style={{
                    position: "relative",
                    border: "0.25rem dashed #B8B8B8",
                    borderRadius: "1.4rem",
                    width: "fit-content",
                    height: "fit-content",
                }}>
                <FlexColumnAlignJustifyCenter
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        gap: "0.6rem",
                    }}>
                    <ImageIcon size={iconSizes.LG} color={themeColors.neutralBlack} />
                    <span className="knowtFontBold" style={{ fontSize: "1.17rem" }}>
                        Image
                    </span>
                </FlexColumnAlignJustifyCenter>
                <input
                    tabIndex={tabIndex}
                    alt="click to add image"
                    type="file"
                    accept="image/*"
                    onChange={async event => await handleImagePickerInputChange(event)}
                    style={{ opacity: 0, height: "7rem", width: "7rem", cursor: "pointer" }}
                />
            </div>
        );
    }

    return (
        <AddRemoveImageButton
            editable
            image={inputImg || defaultImage}
            onDelete={() => {
                setInputImg(null);
                onImageChange({ image: null, height: 0, width: 0 });
            }}
            size="7.4rem"
        />
    );
};
