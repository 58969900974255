import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { preventStopPropogation } from "@/utils/domUtils";

const ZoomableImage = ({
    isOpen,
    onClose,
    img,
    alt,
}: {
    isOpen: boolean;
    onClose: () => void;
    img: string;
    alt: string;
}) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const handleClose = () => {
        setIsZoomed(false);
        onClose();
    };

    const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        const container = e.currentTarget.getBoundingClientRect();
        const x = (e.nativeEvent.clientX - container.left) / container.width;
        const y = (e.nativeEvent.clientY - container.top) / container.height;

        setCursorPosition({ x, y });
        setIsZoomed(!isZoomed);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const container = e.currentTarget.getBoundingClientRect();
        const x = (e.nativeEvent.clientX - container.left) / container.width;
        const y = (e.nativeEvent.clientY - container.top) / container.height;

        setCursorPosition({ x, y });
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: "visible",
                },
            }}
            onBackdropClick={preventStopPropogation}>
            <div
                onClick={preventStopPropogation}
                style={{ overflow: "hidden", position: "relative", width: "100%", height: "100%" }}
                onMouseMove={isZoomed ? handleMouseMove : undefined}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        transformOrigin: `${cursorPosition.x * 100}% ${cursorPosition.y * 100}%`,
                        transition: "transform 0.2s",
                        transform: isZoomed ? "scale(2.3)" : "none",
                    }}>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        alt={alt}
                        src={img}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            backgroundColor: "transparent",
                            cursor: isZoomed ? "zoom-out" : "zoom-in",
                            objectPosition: "center center",
                        }}
                        onClick={handleImageClick}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default ZoomableImage;
