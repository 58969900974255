import { assertTruthy } from "@/utils/assertions";
import { getHtmlFromContent } from "@knowt/editor/helpers/getHtmlFromContent";
import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";
import { Node } from "@knowt/editor/pm/model";
import { Selection } from "@knowt/editor/pm/state";
import { createNodeFromContent, Editor, getTextBetween, posToDOMRect } from "@knowt/editor/react";
import { FLASHCARD_LANGUAGES, REMOVED_LANGUAGES } from "@knowt/syncing/hooks/flashcards/languageUtils";
import { getOppositeFlashcardSide } from "@knowt/syncing/hooks/flashcards/utils";
import { AICompletionType, Language, UpgradeEvent } from "@knowt/syncing/graphql/schema";
import { objectWithout } from "@knowt/syncing/utils/dataCleaning";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { LeapFrog } from "@uiball/loaders";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Image from "next/image";
import { MouseEvent, TouchEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import useDeferred from "use-deferred";
import { ArrowRightIcon, SparklesIcon } from "lucide-react";
import { useIsomorphicLayoutEffect } from "react-use";
import { AILocalOutputType } from "../utils";
import HtmlText from "components/HtmlText";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import {
    AI_PROMPTS,
    AiEditorMutationType,
    AIPrompt,
} from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";
import CircularButton from "@/components/CircularButton";
import { useUpgradePopupContextSelector } from "@/components/Popup/Subscription/UpgradePopupContext";
import ChooseLanguagePopup from "@/components/Popup/ChooseLanguagePopup";
import { DiscardPopup } from "@/components/Popup/AiPopup/AiDiscardPopup";
import { NON_PERSISTENT_HIGHLIGHT_META_KEY } from "@/components/FullPageEditor/extensions/NonPersistentHighlight";
import { HIGHLIGHTS } from "@/components/FullPageEditor/constants";
import {
    EditorFlashcard,
    GetAIResponseFromPromptType,
    NOTE_HIGHLIGHT_OUTPUT_PROMPTS,
    OptimisticOutputType,
    PosStyle,
} from "@/components/FullPageEditor/AiInputMenu/types";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";

export const FLASHCARDS_LIST_WRAPPER_ID = "flashcards-list-wrapper";

const filterOptions = createFilterOptions({
    stringify: (option: AIPrompt) => option.group + option.title,
});

type SelectionInfo = {
    text: string;
    from: number;
    to: number;
};

type AiInputMenuProps = {
    editor: Editor;
    isActive: boolean;
    inputMenu: (AICompletionType | AiEditorMutationType)[];
    highlightMenu: (AICompletionType | AiEditorMutationType)[];
    getAIResponseFromPrompt: GetAIResponseFromPromptType;
    onClose: () => void;
    selectedPrompt?: AIPrompt;
    optimisticText?: string;
    context: {
        [key: string]: string;
    };
    flashcard: EditorFlashcard;
};

const AiInputMenu = ({
    isActive,
    onClose,
    selectedPrompt: selectedPromptProp,
    optimisticText,
    inputMenu,
    highlightMenu,
    getAIResponseFromPrompt,
    editor,
    flashcard,
    context = {},
}: AiInputMenuProps) => {
    const { view } = editor;

    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);
    const isUpgradePopupOpen = useUpgradePopupContextSelector(state => state.isOpen);

    const oppositeFlashcardSide = flashcard[getOppositeFlashcardSide(flashcard.side).toLowerCase()];

    const [isAiWriting, setIsAiWriting] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [posStyle, setPosStyle] = useState<PosStyle>();
    const [inputPos, setInputPos] = useState<number>();
    const { smDown } = useBreakPoints();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const autoCompleteRef = useRef<HTMLDivElement | null>(null);

    const [isDiscardPopupOpen, setIsDiscardPopupOpen] = useState(false);

    const [selectedPrompt, setSelectedPrompt] = useState<AIPrompt | null>(null);

    const [selectionInfo, setSelectionInfo] = useState<SelectionInfo | null>(null);

    const [limboOutput, setLimboOutput] = useState({ markdown: "", html: "" });

    const [optimisticOutput, setOptimisticOutput] = useState<OptimisticOutputType>({ markdown: "", from: 0, to: 0 });

    const inputItemList = inputMenu.map(promptType => AI_PROMPTS[promptType]);
    const highlightItemList = highlightMenu.map(promptType => AI_PROMPTS[promptType]);

    // it is considered a toolbar menu if there is text selected and the current AI output is not selected at the moment
    const isFromSelectionMenu = !!selectionInfo?.text && !optimisticOutput.markdown;

    const [items, setItems] = useState(isFromSelectionMenu ? highlightItemList : inputItemList);

    const [isChooseLangPopupOpen, setIsChooseLangPopupOpen] = useState(false);
    const { execute: getSelectedLanguage, resolve } = useDeferred();

    // minimum is 1, as inserting at position 0 will create a new paragraph node.
    // TODO: the insertinoPos logic is to be revisited.
    const insertionPos = Math.max(1, (inputPos || view.state.doc.content.size) - 1);

    const focusHighlight = {
        color: HIGHLIGHTS.blue.cssVar,
        add(from: number, to: number) {
            editor.commands.setMeta(NON_PERSISTENT_HIGHLIGHT_META_KEY, { type: "add", from, to, color: this.color });
        },
        remove(from: number, to: number) {
            editor.commands.setMeta(NON_PERSISTENT_HIGHLIGHT_META_KEY, { type: "remove", from, to, color: this.color });
        },
    };

    const resetFocusHighlight = () => {
        if (selectionInfo) focusHighlight.remove(selectionInfo.from, selectionInfo.to);
        focusHighlight.remove(optimisticOutput.from, optimisticOutput.to);
    };

    useIsomorphicLayoutEffect(() => {
        if (isActive && selectedPromptProp) onPromptClick(selectedPromptProp);
    }, [isActive, selectedPromptProp]);

    useEffect(() => {
        if (optimisticText) {
            setItems([]);
            // we need to delay this because we need the position of the input menu to be calculated
            setTimeout(() => handleOptimisticInsertion(optimisticText), 15);
        }
    }, [optimisticText]);

    const handleClose = () => {
        reset();
        onClose();

        editor.chain().focus().setTextSelection(view.state.selection.to).run();
    };

    const reset = () => {
        resetFocusHighlight();
        setInputValue("");
        setLimboOutput({ markdown: "", html: "" });
        setOptimisticOutput({ markdown: "", from: 0, to: 0 });
        setItems(inputItemList);
        setSelectedPrompt(null);
        setSelectionInfo(null);
        setInputPos(undefined);
        setIsDiscardPopupOpen(false);
        setPosStyle(undefined);
        resetPaddingBottom();
    };

    const resetPaddingBottom = () => {
        // we're assuming the initial padding was 0px
        const wrapperEl = document.getElementById(FLASHCARDS_LIST_WRAPPER_ID);
        assertTruthy(wrapperEl, "wrapperEl could not be found");

        wrapperEl.style.paddingBottom = "0px";
    };

    const handleLimboMode = async (text: string) => {
        // before translating partial sum to html, we need to replace all occurrences of \n with \n\n
        // because the markdown parser will convert \\n to \n, which is what we want
        // but if we don't do this, then the markdown parser will convert \n to <br> which is not what we want
        text = text.replace(/\n/g, "\n\n");

        setInputValue("");

        const newLimboOutput = { markdown: text, html: getHtmlFromContent({ content: text, type: "flashcard" }) };
        setLimboOutput(newLimboOutput);

        setItems([...NOTE_HIGHLIGHT_OUTPUT_PROMPTS].map(promptType => AI_PROMPTS[promptType]));

        setSelectedPrompt(null);

        setTimeout(() => scrollAiMenuIntoViewIfNeeded(), 50);
    };

    const handleOptimisticInsertion = async (text: string) => {
        resetFocusHighlight();
        setInputValue("");

        const { start, end } = await insertAiContent({ editor, text, start: optimisticOutput.from || insertionPos });

        resetFocusHighlight();
        focusHighlight.add(start, end);

        setOptimisticOutput({ markdown: text, from: start, to: end });
        setInputPos(end);

        const options = [
            AiEditorMutationType.DONE,
            AICompletionType.MAKE_LONGER,
            AICompletionType.MAKE_SHORTER,
            AiEditorMutationType.DISCARD,
        ];

        setItems(options.map(promptType => AI_PROMPTS[promptType]));

        updateMenuPosition({ selectionText: text, targetPos: end });

        setSelectedPrompt(null);
    };

    const insertText = async (aiOutput: AILocalOutputType, prompt: AIPrompt) => {
        if (aiOutput.output) {
            // standard insertion, i.e. on the same side
            await handleStandardInsertion(aiOutput.output, prompt);
        } else {
            await handleBothSidesInsertion(aiOutput);
        }
    };

    // always insert on the same side
    const handleStandardInsertion = async (text: string, prompt: AIPrompt) => {
        if (prompt.type === AICompletionType.TRANSLATE) {
            handleClose();

            return await insertAiContent({
                editor,
                text,
                start: insertionPos,
                insertType: AiEditorMutationType.INSERT_BELOW,
            });
        }

        // if some text is already highlighted, or we're in optimistic mode, we want to render the next usages of the AI in limbo mode
        if (isFromSelectionMenu || optimisticOutput.markdown) {
            return handleLimboMode(text);
        } else {
            return handleOptimisticInsertion(text);
        }
    };

    // involves inserting in the other side
    const handleBothSidesInsertion = async (aiOutput: AILocalOutputType) => {
        if (!isFromSelectionMenu) {
            await insertAiContent({ editor, text: aiOutput[flashcard.side], start: insertionPos });
        }

        const oppositeSide = getOppositeFlashcardSide(flashcard.side);

        // if we get here we've inserted into both sides, we want to close this menu and open it on the opposite side, in optimistic mode
        handleClose();

        oppositeFlashcardSide.ref.current.openAiInputMenu({ optimisticText: aiOutput[oppositeSide] });
    };

    const prefillPrompt = (prompt: AIPrompt) => {
        let newInput = prompt.prefill;

        const oppositeSideText = oppositeFlashcardSide.content;
        if (oppositeSideText) {
            const plainText = getPlainTextFromContent({ content: oppositeSideText, type: "flashcard" });
            newInput += plainText.split(" ")?.length < 10 ? plainText : "";
        }

        setInputValue(newInput);
    };

    // if no item passed in, it will be ASK or selectedItem prompt request sent to the server
    const onPromptClick = async (item?: AIPrompt) => {
        // This is needed bc setSelectedLanguage is not updated in time, since its async
        let localLanguage = "";
        if (item) {
            setSelectedPrompt(item);

            if (item.type === AICompletionType.TRANSLATE_FLASHCARD) {
                const termLanguage = flashcard.term.language;
                const definitionLanguage = flashcard.definition.language;

                if (termLanguage === definitionLanguage || !definitionLanguage || !!oppositeFlashcardSide.content) {
                    setIsChooseLangPopupOpen(true);
                    localLanguage = await getSelectedLanguage();
                    if (!localLanguage) {
                        return handleClose();
                    }
                }
            }

            if (
                !isFromSelectionMenu &&
                !limboOutput.markdown &&
                !optimisticOutput.markdown &&
                item.type !== AICompletionType.CONTINUE_WRITING
            ) {
                // If it is not a highlight menu, or in a temporary state in the middle, then we need to let the user input more text.
                // If it is a highlight menu, we will let it pass, and auto-submit the prompt (using the selected text as the input)
                return prefillPrompt(item);
            }
        }

        const genericPrompt = AICompletionType.GENERIC_FLASHCARD;
        const prompt = selectedPrompt || item || AI_PROMPTS[genericPrompt];

        assertTruthy(prompt, "Prompt is undefined");

        switch (prompt.type) {
            case AiEditorMutationType.DONE: {
                return handleClose();
            }
            case AiEditorMutationType.DISCARD: {
                if (optimisticOutput) {
                    editor.chain().deleteRange({ from: optimisticOutput.from, to: optimisticOutput.to }).run();
                }
                return handleClose();
            }
            case AiEditorMutationType.INSERT_BELOW:
            case AiEditorMutationType.INSERT_ABOVE:
            case AiEditorMutationType.REPLACE: {
                // in the case of insertion above,
                // selectInfo will no longer be valid as the current focused node shifts down
                // reset focus highlight before that happens
                resetFocusHighlight();
                onClose();

                await insertAiContent({
                    editor,
                    text: limboOutput.markdown,
                    start: insertionPos,
                    selection: selectionInfo || optimisticOutput,
                    insertType: prompt.type,
                });

                return handleClose();
            }
            default: {
                setIsAiWriting(true);

                try {
                    const selectedText = limboOutput.markdown || optimisticOutput.markdown || selectionInfo?.text;

                    const output = await getAIResponseFromPrompt({
                        side: flashcard?.side,
                        selectedText,
                        input: inputValue,
                        prompt,
                        targetLanguage: localLanguage,
                    });

                    await insertText(output, prompt);
                } catch (e) {
                    handleClose();
                    if (e?.errors?.[0]?.message?.includes("usage limit")) {
                        openUpgradePopup({
                            event: UpgradeEvent.MAX_PROMPT,
                            context: {
                                itemId: context?.itemId,
                            },
                            onClose: () => handleClose(),
                        });
                    } else {
                        toast.error(e.message);
                    }
                }
                setIsAiWriting(false);
            }
        }
    };

    useDOMEventListener("mousemove", () => {
        const suggestionsList = document.querySelector(".MuiAutocomplete-listbox") as HTMLElement;
        // remove the pointerEvents lock that was set when we "auto scrolled if needed"
        if (suggestionsList) suggestionsList.style.pointerEvents = "auto";
    });

    useEffect(() => {
        if (isActive) {
            const selectionInfo = updateSelectionInfo();
            updateMenuPosition({ selectionText: selectionInfo?.text, targetPos: view.state.selection.to });
        }
    }, [isActive]);

    const updateSelectionInfo = (): SelectionInfo | null => {
        if (view.state.selection.empty) {
            setInputPos(view.state.selection.to);
            return null;
        }

        const { from, to } = view.state.selection;
        const text = getTextBetween(view.state.doc, { from, to });

        setSelectionInfo({ from, to, text });
        setInputPos(to + 1);
        setItems(highlightItemList);

        focusHighlight.add(from, to);

        return { from, to, text };
    };

    const updateMenuPosition = ({ selectionText, targetPos }: { selectionText?: string; targetPos: number }) => {
        const { width: editorWidth, left: editorViewPortLeft } = view.dom.getBoundingClientRect();
        const { left: wrapperListViewPortLeft, top: wrapperListViewPortTop } = getWrapperListBoundingClientRect();

        setPosStyle({
            width: editorWidth,
            left: editorViewPortLeft - wrapperListViewPortLeft,
            top: getMenuViewPortTop({ selectionText, targetPos }) - wrapperListViewPortTop,
        });

        // wait a bit for portal to mount onto dom -> otherwise `inputRef`
        // and querying for dom elements will yield nothing
        setTimeout(() => scrollAiMenuIntoViewIfNeeded(), 50);
    };

    const getWrapperListBoundingClientRect = () => {
        const wrapperListEl = document.getElementById(FLASHCARDS_LIST_WRAPPER_ID);
        assertTruthy(wrapperListEl, "wrapperListEl could not be found");

        return wrapperListEl.getBoundingClientRect();
    };

    const getMenuViewPortTop = ({ selectionText, targetPos }: { selectionText?: string; targetPos: number }) => {
        // TODO: find a better way to calculate the line height; approximation is error prone.
        const APPROXIMATE_LINE_HEIGHT = 30;

        const isFromSelectionMenu = !!selectionText && !optimisticOutput.markdown;
        const additionalTopOffset = APPROXIMATE_LINE_HEIGHT * (isFromSelectionMenu ? 1 : 0);

        const cursorDomRect = posToDOMRect(view, targetPos, targetPos);

        return cursorDomRect.top + additionalTopOffset;
    };

    const scrollAiMenuIntoViewIfNeeded = () => {
        const suggestionEl = document.querySelector(".MuiAutocomplete-popper");
        assertTruthy(suggestionEl, "suggestionEl could not be found");

        const { bottom: suggestionElBottom } = suggestionEl.getBoundingClientRect();
        const yDiff = suggestionElBottom - window.innerHeight;

        if (yDiff < 0) {
            inputRef.current?.focus();
            return;
        }

        const wrapperEl = document.getElementById(FLASHCARDS_LIST_WRAPPER_ID);
        assertTruthy(wrapperEl, "wrapperEl could not be found");

        const newPaddingBottom = yDiff + 200;
        wrapperEl.style.paddingBottom = `${newPaddingBottom}px`;

        suggestionEl.scrollIntoView({ behavior: "smooth", block: "nearest" });

        inputRef.current?.focus({ preventScroll: true });
    };

    const maybeClose = (event?: MouseEvent | TouchEvent) => {
        if (isChooseLangPopupOpen || isAiWriting || (smDown && event?.type !== "touchstart")) return;
        // either the input is empty, or the input is the same as the prefilled prompt
        // (and there is no text in limbo mode that has not been written to the document yet)

        if ((!inputValue.trim() || inputValue === selectedPrompt?.prefill) && !limboOutput.markdown) {
            return handleClose();
        }

        setIsDiscardPopupOpen(true);
    };

    const renderInput = () => {
        return (
            <Autocomplete
                ref={autoCompleteRef}
                openOnFocus
                open={!isAiWriting}
                slotProps={{
                    popper: {
                        style: { zIndex: 10 },
                        placement: "bottom-start",
                        modifiers: [{ name: "flip", enabled: false }],
                        disablePortal: true,
                    },
                }}
                onClose={(_event, reason) => {
                    if (reason === "escape") {
                        maybeClose();
                    }
                }}
                autoHighlight
                clearOnEscape
                clearOnBlur
                componentsProps={{
                    paper: {
                        sx: {
                            fontSize: "14px",
                            margin: "0.6rem 0 0.6rem",
                            color: "var(--color-neutral-black)",
                            borderRadius: "5px",
                            boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)",
                            width: "100%",
                            minWidth: "32rem",
                            backgroundColor: "var(--color-neutral-white)",
                            '[data-theme="dark"] &': {
                                backgroundColor: "#333333",
                            },
                            marginBottom: "5rem",
                            "& .MuiAutocomplete-option": {
                                margin: "0.2rem 0.5rem",
                                paddingRight: "1rem",
                                borderRadius: "5px",
                                height: "3.2rem",
                                "& > div > img": { width: "1.7rem", height: "auto", marginRight: "1rem" },
                                "& > div > span": {
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    marginRight: "5rem",
                                },
                                "& > img.enter": {
                                    visibility: "hidden",
                                },
                            },
                            "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
                                backgroundColor: themeColors.neutral1,
                                "& > img.enter": {
                                    visibility: "visible",
                                },
                            },
                            "& .MuiAutocomplete-listbox > li:not(:last-child)": {
                                paddingBottom: "0.5rem",
                            },
                            "& .MuiAutocomplete-noOptions": {
                                display: "none",
                            },
                            "& .MuiAutocomplete-groupUl .MuiAutocomplete-option": {
                                paddingLeft: "1rem",
                            },
                            "& .MuiAutocomplete-groupLabel": {
                                paddingLeft: "1.5rem",
                                lineHeight: "3rem",
                                height: "3rem",
                                fontSize: "13px",
                                fontWeight: "normal",
                                color: "#c0c0c0",
                                backgroundColor: "var(--color-neutral-white)",
                                '[data-theme="dark"] &': {
                                    backgroundColor: "#333333",
                                },
                                borderTop: "1px solid var(--color-line)",
                            },
                            "& .MuiAutocomplete-listbox > li:first-child .MuiAutocomplete-groupLabel": {
                                borderTop: "0px solid",
                            },
                            "& .MuiAutocomplete-listbox": {
                                paddingLeft: 0,
                                marginLeft: "0",
                                "& ul": {
                                    margin: "0",
                                    paddingLeft: "0",
                                },
                            },
                            "& .MuiAutocomplete-listbox > li:first-child": {
                                marginTop: "0.6rem",
                            },
                            "& .MuiAutocomplete-listbox > li:last-child": {
                                marginBottom: "0.6rem",
                            },
                        },
                    },
                }}
                options={items.filter((item): item is AIPrompt => !!item)}
                groupBy={optimisticOutput.markdown ? undefined : option => option.group}
                noOptionsText={"no options"}
                getOptionLabel={option => option.title || ""}
                renderOption={(props, option, state) => {
                    const { inputValue } = state;
                    const matches = match(option.title, inputValue, { insideWords: true });
                    const parts = parse(option.title, matches);
                    return (
                        <li {...props} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Image src={option.image} alt={option.keywords} width={50} height={50} />
                                <span>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{ fontWeight: inputValue && part.highlight ? "600" : undefined }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </span>
                            </div>
                            <Image
                                className={"enter"}
                                src={`${ASSETS_URL}/images/enter.png`}
                                alt={option.keywords}
                                width={15}
                                height={10}
                            />
                        </li>
                    );
                }}
                filterOptions={filterOptions}
                inputValue={inputValue}
                onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                        const isEnterPressed =
                            event?.nativeEvent?.inputType === "insertLineBreak" || event?.nativeEvent?.key === "Enter";

                        if (isEnterPressed) {
                            return onPromptClick();
                        }
                        if (value === "") {
                            setSelectedPrompt(null);
                        }
                        setInputValue(value);
                    }
                }}
                onChange={(_event, item: AIPrompt, reason) => {
                    if (reason === "selectOption") {
                        if (item) {
                            return onPromptClick(item);
                        }
                    }
                }}
                value={""}
                renderInput={params => (
                    <Form ref={params.InputProps.ref} warn={false} style={{ display: "flex", flexDirection: "column" }}>
                        {limboOutput.html && (
                            <div
                                style={{
                                    display: "flex",
                                    backgroundColor: "var(--color-neutral-white)",
                                    height: "100%",
                                    width: "100%",
                                    color: "var(--color-neutral-black)",
                                    overflow: "auto",
                                    borderRadius: "1rem 1rem 0 0",
                                }}>
                                <HtmlText
                                    text={limboOutput.html}
                                    style={{ maxHeight: 302, padding: "0.5rem 0.8rem", fontSize: "16px" }}
                                />
                            </div>
                        )}

                        <FlexRowAlignCenter
                            style={{ flex: 1, minHeight: "5.5rem" }}
                            onClick={event => {
                                event.stopPropagation();
                            }}>
                            <SparklesIcon style={{ marginLeft: spacing.SM, marginRight: spacing.XS_2 }} />
                            {isAiWriting ? (
                                <FlexRowAlignCenter style={{ flex: 1, color: "#c0c0c0", gap: "1rem" }}>
                                    AI is writing
                                    <LeapFrog speed={2} size={25} color={"#c0c0c0"} />
                                </FlexRowAlignCenter>
                            ) : (
                                <Input
                                    placeholder={
                                        limboOutput.markdown
                                            ? "Tell the AI what to do next..."
                                            : optimisticOutput.markdown
                                            ? "Ask AI to edit or do anything with the selected text"
                                            : "Ask AI anything (Ex: What is mitosis)"
                                    }
                                    onKeyDown={event => {
                                        if (event.key === "Backspace" && !inputValue && !limboOutput.markdown) {
                                            event.preventDefault();
                                            handleClose();
                                        }
                                    }}
                                    {...objectWithout(params.inputProps, "ref")}
                                    ref={node => {
                                        inputRef.current = node;
                                        params.inputProps.ref.current = node;
                                    }}
                                />
                            )}
                            <CircularButton
                                disabled={isAiWriting || !inputValue}
                                type={"submit"}
                                radius={"2.75rem"}
                                sx={{
                                    backgroundColor: "#50d2c2",
                                    transition: "filter 0.18s ease-in-out, transform 0.18s ease-in-out",
                                    "&:disabled": { filter: "grayscale(70%)" },
                                    marginRight: spacing.SM,
                                    marginLeft: spacing.XS_2,
                                }}
                                onClick={event => {
                                    event.preventDefault();
                                    onPromptClick();
                                }}>
                                <ArrowRightIcon color={themeColors.pureWhite} />
                            </CircularButton>
                        </FlexRowAlignCenter>
                    </Form>
                )}
            />
        );
    };

    return (
        <>
            {isActive && posStyle && (
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={maybeClose}>
                    <Section
                        style={{
                            left: `${posStyle.left}px`,
                            width: `${posStyle.width}px`,
                            top: `${posStyle.top}px`,
                        }}>
                        <div style={{ position: "relative" }}>
                            <FlexColumn>{renderInput()}</FlexColumn>
                        </div>
                    </Section>
                </ClickAwayListener>
            )}
            <ChooseLanguagePopup
                currSelectedLanguage={null}
                isOpen={isChooseLangPopupOpen}
                onClose={() => {
                    setIsChooseLangPopupOpen(false);
                    resolve("");
                }}
                listOfLanguages={Array.from(new Set(Object.keys(FLASHCARD_LANGUAGES))).filter(
                    (language: Language) => !REMOVED_LANGUAGES.has(language)
                )}
                onSelectLanguage={async (selectedLang: Language) => {
                    flashcard.onChooseLanguage(selectedLang);
                    resolve(selectedLang);
                    setIsChooseLangPopupOpen(false);
                }}
            />
            <DiscardPopup
                isOpen={isDiscardPopupOpen && !isUpgradePopupOpen}
                onDiscard={handleClose}
                onCancel={() => setIsDiscardPopupOpen(false)}
            />
        </>
    );
};

export default AiInputMenu;

const Section = styled.section`
    position: absolute;
    z-index: 100;
`;

const Form = styled.form<{
    warn: boolean;
}>`
    @keyframes shake {
        33% {
            transform: skew(1deg, 1deg);
        }
        66% {
            transform: skew(-1deg, -1deg);
        }
    }
    position: relative;
    display: flex;
    z-index: 99;
    background-color: var(--color-neutral-white);
    border-radius: 1rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    ${({ warn }) =>
        warn &&
        `
        animation: shake 0.35s ease-in-out;
    `};
`;

const Input = styled.input`
    border: none;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background-color: transparent;
    padding-block: 1.5rem;
    border-radius: 0.5rem;
    font-size: 16px;
    flex: 1;
    resize: none;
    color: var(--color-neutral-black);

    &:focus {
        outline: none;
    }
`;

const EMPTY_PARAGRAPH_NODE_SIZE = 2;

type InsertType = AiEditorMutationType.INSERT_BELOW | AiEditorMutationType.INSERT_ABOVE | AiEditorMutationType.REPLACE;

/**
 * Takes markdown or html as input and chunk insert it into the editor in one shot.
 */
export const insertAiContent = async ({
    text,
    start,
    insertType,
    selection,
    editor,
}: {
    text?: string;
    start: number;
    insertType?: InsertType;
    selection?: { from: Selection["from"]; to: Selection["to"] };
    editor: Editor;
}): Promise<{
    start: number;
    end: number;
}> => {
    if (!text) return { start, end: start };

    const actualStart = initProgress({ editor, start, insertType, selection });

    const html = getHtmlFromContent({ content: text, type: "flashcard" });
    editor.commands.insertContentAt(actualStart, html, { updateSelection: true });

    const node = createNodeFromContent(html, editor.schema);
    const nodeSize = node instanceof Node ? node.nodeSize : node.size;

    return { start: actualStart, end: actualStart + nodeSize - 1 };
};

const initProgress = ({
    editor,
    start,
    insertType,
    selection,
}: {
    editor: Editor;
    start: number;
    insertType?: InsertType;
    selection?: { from: Selection["from"]; to: Selection["to"] };
}) => {
    if (!insertType) return start;

    if (insertType === AiEditorMutationType.REPLACE) {
        if (!selection) return start;
        editor.commands.deleteRange({ from: selection.from, to: selection.to });
        return selection.from;
    }

    const resolvedPos = editor.view.state.doc.resolve(start);
    const node = resolvedPos.node();

    if (node.nodeSize <= EMPTY_PARAGRAPH_NODE_SIZE) return start;

    if (insertType === AiEditorMutationType.INSERT_BELOW) {
        const afterPos = resolvedPos.after();
        const nextNodePos = Math.min(editor.view.state.doc.nodeSize, afterPos);
        editor.commands.insertContentAt(nextNodePos, "<p></p>");
        return nextNodePos + 1;
    }

    if (insertType === AiEditorMutationType.INSERT_ABOVE) {
        const beforePos = resolvedPos.before();
        const prevNodePos = Math.max(0, beforePos);
        editor.commands.insertContentAt(prevNodePos, "<p></p>");
        return prevNodePos + 1;
    }

    return start;
};
