import { FLASHCARD_LANGUAGES, getDisplayedLanguageText } from "@knowt/syncing/hooks/flashcards/languageUtils";
import { useCombinedState } from "@knowt/syncing/utils/hooks/useCombinedState";
import Collapse from "@mui/material/Collapse";
import dynamic from "next/dynamic";
import AccentButtons from "@/components/AccentButtons";
import { FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import ClickableText from "@/components/styled/button/ClickableText";
import ConditionalTooltip from "@/components/wrappers/ConditionalTooltip";

const ChooseLanguagePopup = dynamic(() => import("@/components/Popup/ChooseLanguagePopup"));

export const AccentSuggestionsWrapper = ({ isAccentButtonsVisible, language, onAccentSelected }) => {
    // declare it twice (here and in `AccentButtons`), somehow `Collapse` is actually still renders even when the `AccentButtons` return `null`
    if (language === FLASHCARD_LANGUAGES.ENGLISH || !language) {
        return null;
    }

    return (
        <Collapse in={isAccentButtonsVisible} timeout={300} unmountOnExit={true}>
            <FlexRowAlignCenter style={{ gap: "1.5rem", flexWrap: "wrap" }}>
                <AccentButtons language={language} onClick={onAccentSelected} />
            </FlexRowAlignCenter>
        </Collapse>
    );
};

export const ClickableLanguagePopup = ({ side, language, onLanguageSelected, isClickableTextVisible }) => {
    const [chooseLangStates, updateChooseLangStates] = useCombinedState({
        isOpen: false,
        previousFocusElement: null,
    });

    const closeChooseLangPopup = () => {
        updateChooseLangStates({ isOpen: false, previousFocusElement: null });
        // re-focus to previously focused element (in this case, the term/definition input)
        setTimeout(() => chooseLangStates.previousFocusElement.focus(), 500);
    };

    return (
        <>
            <ConditionalTooltip tooltip={`tell us what language your ${side.toLowerCase()}s are in`}>
                <ClickableText
                    tabIndex="-1" // dont allow to focus
                    className="bodyBold1"
                    style={{
                        color: "#58d4c5",
                        visibility: isClickableTextVisible ? "visible" : "hidden",
                    }}
                    onClick={() => {
                        updateChooseLangStates({ isOpen: true, previousFocusElement: document.activeElement });
                    }}>
                    {getDisplayedLanguageText(FLASHCARD_LANGUAGES[language])}
                </ClickableText>
            </ConditionalTooltip>
            {/*TODO: the only reason we use FLASHCARD_LANGUAGES[language] is because of the typo we had to FINMISH ->*/}
            {/* FINNISH. Since the object is just a direct mapping, this works*/}
            <LazyLoaded load={chooseLangStates.isOpen}>
                <ChooseLanguagePopup
                    isOpen={chooseLangStates.isOpen}
                    onClose={closeChooseLangPopup}
                    listOfLanguages={Array.from(new Set(Object.values(FLASHCARD_LANGUAGES)))}
                    currSelectedLanguage={FLASHCARD_LANGUAGES[language]}
                    onSelectLanguage={l => {
                        onLanguageSelected(l);
                        closeChooseLangPopup();
                    }}
                />
            </LazyLoaded>
        </>
    );
};
