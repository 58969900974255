"use client";

import MUICircularProgress, { CircularProgressProps as MuiCircularProgressProps } from "@mui/material/CircularProgress";
import React, { ReactNode, useRef } from "react";
import { FlexColumn } from "./Flex";
import { themeColors } from "@/utils/themeColors";
import { useTheme } from "@/utils/theme";

type CircularProgressProps = Omit<MuiCircularProgressProps, "color"> & {
    color?: string;
    bgColor?: string;
    label?: string | ReactNode;
    disableAnimation?: boolean;
    labelStyle?: React.CSSProperties;
};

/**
 * A normal MuiCircularProgress with additional custom "bg stroke color" and ease "set of bar color"
 */
const CircularProgress = ({
    color,
    bgColor,
    label = "",
    labelStyle,
    disableAnimation = false,
    title = "Knowt progress track",
    ...muiCircularProgressProps
}: CircularProgressProps) => {
    const { isDarkMode } = useTheme();
    const progressValueRef = useRef(null);

    if (disableAnimation && progressValueRef.current) {
        progressValueRef.current.querySelector("svg circle").style.transition = "none";
    }

    return (
        <FlexColumn style={{ position: "relative", alignItems: "center" }}>
            <MUICircularProgress
                variant="determinate"
                sx={{ color: bgColor || (!isDarkMode ? "#f3f3f3" : "#5b5b5b") }}
                size={4}
                thickness={6}
                title={title}
                {...muiCircularProgressProps}
                value={100}
            />
            <MUICircularProgress
                id="circular-progess"
                ref={progressValueRef}
                variant="determinate"
                sx={{ color: color || "#50d2c2", position: "absolute", left: 0 }}
                size={4}
                thickness={6}
                title={title}
                {...muiCircularProgressProps}
            />
            {label && (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        fontSize: (Number(muiCircularProgressProps.size.toString()) || 4) / 4,
                        fontWeight: "600",
                        color: themeColors.neutralBlack,
                        ...labelStyle,
                    }}>
                    {label}
                </div>
            )}
        </FlexColumn>
    );
};

export default CircularProgress;
